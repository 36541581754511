import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import * as XLSX from 'xlsx';
import { Clock, MessageSquare, ChevronLeft, Menu, X, Upload, Send } from 'lucide-react';

const AnswerQuestionsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedDocuments } = location.state || { selectedDocuments: [] };
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const [predefinedQuestions, setPredefinedQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userQuestions, setUserQuestions] = useState([]);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(true);
  const [error, setError] = useState(null);

  const API_BASE_URL = 'https://m-zbr0.onrender.com/api';

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Fetch predefined questions
        const predefinedResponse = await axios.get(`${API_BASE_URL}/predefinedQuestion/`);
        setPredefinedQuestions(predefinedResponse.data);

        // Fetch user questions
        await fetchUserQuestions();
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setError('Failed to load initial data. Please try again later.');
      }
    };

    fetchInitialData();
  }, []);

  const fetchUserQuestions = async () => {
    setIsLoadingQuestions(true);
    try {
      const userEmail = localStorage.getItem('userEmail');
      if (!userEmail) {
        throw new Error('User email not found');
      }
      const response = await axios.get(`${API_BASE_URL}/user-questions/?userEmail=${userEmail}`);
      setUserQuestions(response.data);
    } catch (error) {
      console.error('Error fetching user questions:', error);
      setError('Failed to load your questions. Please try again later.');
    } finally {
      setIsLoadingQuestions(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const headerRow = jsonData[0];
        const questionColumnIndex = headerRow.findIndex(
          (header) => header?.toLowerCase() === 'question'
        );

        if (questionColumnIndex === -1) {
          setError('No "Question" column found in the Excel file');
          return;
        }

        const extractedQuestions = jsonData
          .slice(1)
          .map((row) => row[questionColumnIndex])
          .filter(Boolean);

        setQuestions(extractedQuestions);
        setError(null);
      } catch (error) {
        console.error('Error processing Excel file:', error);
        setError('Failed to process Excel file. Please check the file format.');
      }
    };

    reader.onerror = () => {
      setError('Failed to read Excel file');
    };

    reader.readAsArrayBuffer(file);
  };

  const handleQuestionSubmit = async (e) => {
    e.preventDefault();
    if (!question.trim()) return;

    setLoading(true);
    setError(null);

    try {
      const userEmail = localStorage.getItem('userEmail');
      const response = await axios.post(`${API_BASE_URL}/answer-question/`, {
        question,
        documentIds: selectedDocuments,
        userEmail,
      });

      setAnswer(response.data.answer);
      await fetchUserQuestions(); // Refresh user questions
      setQuestion(''); // Clear input after successful submission
    } catch (error) {
      console.error('Error getting answer:', error);
      setError(error.response?.data?.error || 'Failed to get answer. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePredefinedQuestionClick = async (predefinedQuestion) => {
    setQuestion(predefinedQuestion);
    setSidebarOpen(false); // Close sidebar on mobile after selection
    
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${API_BASE_URL}/answer-question/`, {
        question: predefinedQuestion,
        documentIds: selectedDocuments,
        userEmail: localStorage.getItem('userEmail'),
      });

      setAnswer(response.data.answer);
      await fetchUserQuestions();
    } catch (error) {
      console.error('Error getting answer:', error);
      setError(error.response?.data?.error || 'Failed to get answer. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleExcelQuestionsSubmit = async () => {
    if (questions.length === 0) return;

    setLoading(true);
    setError(null);
    setAnswers([]);

    try {
      for (const question of questions) {
        const response = await axios.post(`${API_BASE_URL}/answer-question/`, {
          question,
          documentIds: selectedDocuments,
          userEmail: localStorage.getItem('userEmail'),
        });

        setAnswers((prevAnswers) => [
          ...prevAnswers,
          { question, answer: response.data.answer },
        ]);
      }
      await fetchUserQuestions();
    } catch (error) {
      console.error('Error processing Excel questions:', error);
      setError('Failed to process some questions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center p-8 text-center text-gray-400 bg-[#40414F] rounded-lg">
      <MessageSquare size={48} className="mb-4 opacity-50" />
      <h3 className="text-xl font-semibold mb-2">No Questions Yet</h3>
      <p className="text-sm">Start by asking a question below or selecting a predefined question from the sidebar.</p>
    </div>
  );

  const UserQuestionCard = ({ question: q }) => (
    <div className="bg-[#40414F] rounded-lg p-4 mb-4 hover:bg-[#4A4B59] transition-colors">
      <div className="flex justify-between items-start mb-2">
        <h4 className="font-semibold text-white break-words max-w-[80%]">{q.question}</h4>
        <div className="flex items-center text-gray-400 text-sm whitespace-nowrap ml-2">
          <Clock size={14} className="mr-1" />
          {new Date(q.created_at).toLocaleDateString()}
        </div>
      </div>
      <div className="pl-4 border-l-2 border-[#10A37F] mt-3">
        <ReactMarkdown className="text-gray-300 text-sm prose prose-invert">
          {q.answer}
        </ReactMarkdown>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col md:flex-row h-screen bg-[#343541] text-white overflow-hidden">
      {/* Sidebar */}
      <div 
        className={`md:w-64 bg-[#40414F] shadow-md flex flex-col fixed md:static inset-y-0 left-0 z-50 transform ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'
        } transition-transform duration-200 ease-in-out`}
      >
        <div className="p-4 border-b border-[#4A4A4A] flex justify-between items-center">
          <h1 className="text-2xl font-bold text-white">Project_M</h1>
          <button 
            onClick={() => setSidebarOpen(false)} 
            className="md:hidden text-white hover:text-gray-300"
          >
            <X size={24} />
          </button>
        </div>
        
        <div className="flex-1 overflow-y-auto p-4">
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-3">Predefined Questions</h2>
            <div className="space-y-2">
              {predefinedQuestions.map((q) => (
                <button
                  key={q.id}
                  onClick={() => handlePredefinedQuestionClick(q.question)}
                  className="w-full text-left p-2 rounded hover:bg-[#565869] transition-colors text-sm"
                >
                  {q.question}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="flex-1 flex flex-col">
        {/* Mobile header */}
        <div className="md:hidden p-4 bg-[#40414F] flex justify-between items-center">
          <button 
            onClick={() => setSidebarOpen(true)}
            className="text-white hover:text-gray-300"
          >
            <Menu size={24} />
          </button>
          <h1 className="text-xl font-bold">Questions</h1>
          <div className="w-6" /> {/* Spacer for alignment */}
        </div>

        {/* Main content area */}
        <div className="flex-1 overflow-y-auto p-4 md:p-6">
          {error && (
            <div className="bg-red-500/10 border border-red-500/50 text-red-500 p-4 rounded-md mb-4">
              {error}
            </div>
          )}

          <div className="mb-6">
            <h2 className="text-2xl font-bold mb-2">Your Questions History</h2>
            <p className="text-gray-400 text-sm">View and revisit your previous questions and answers</p>
          </div>

          {isLoadingQuestions ? (
            <div className="flex items-center justify-center p-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#10A37F]"></div>
            </div>
          ) : userQuestions.length === 0 ? (
            <EmptyState />
          ) : (
            <div className="space-y-4">
              {userQuestions.map((q) => (
                <UserQuestionCard key={q.id} question={q} />
              ))}
            </div>
          )}

          {answers.length > 0 && (
            <div className="mt-8 bg-[#40414F] p-4 rounded-lg border border-[#565869]">
              <h2 className="text-xl font-bold mb-4">Batch Answers</h2>
              {answers.map((item, index) => (
                <div key={index} className="mb-4 last:mb-0 bg-[#4A4B59] p-4 rounded-md">
                  <p className="text-[#E3E3E3] font-semibold mb-2">
                    Q: {item.question}
                  </p>
                  <div className="pl-4 border-l-2 border-[#10A37F]">
                    <ReactMarkdown className="text-[#E3E3E3] prose prose-invert">
                      {item.answer}
                    </ReactMarkdown>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Input area */}
        <div className="bg-[#40414F] p-4 border-t border-[#4A4A4A]">
          <form onSubmit={handleQuestionSubmit} className="space-y-4">
            <div className="flex items-end space-x-2">
              <div className="flex-1">
                <textarea
                  className="w-full p-3 bg-[#343541] border border-[#565869] rounded-md resize-none text-white placeholder-gray-400 focus:outline-none focus:border-[#10A37F]"
                  rows="2"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  placeholder="Type your question here..."
                  disabled={loading}
                />
              </div>
              <button
                type="submit"
                disabled={loading || !question.trim()}
                className={`px-4 py-3 rounded-md flex items-center space-x-2 ${
                  loading || !question.trim()
                    ? 'bg-gray-600 cursor-not-allowed'
                    : 'bg-[#10A37F] hover:bg-[#0B8C6E]'
                }`}
              >
                <Send size={18} />
                <span className="hidden sm:inline">Send</span>
              </button>
            </div>

            <div className="flex items-center space-x-2">
              <div className="flex-1">
                <input
                  type="file"
                  accept=".xlsx,.xls"
                  onChange={handleFileChange}
                  className="hidden"
                  id="excel-upload"
                  disabled={loading}
                />
                <label
                  htmlFor="excel-upload"
                  className={`flex items-center space-x-2 px-4 py-2 rounded-md border border-[#565869] cursor-pointer ${
                    loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#4A4B59]'
                  }`}
                >
                  <Upload size={18} />
                  <span>Upload Excel</span>
                </label>
              </div>
              <button
                type="button"
                onClick={handleExcelQuestionsSubmit}
                disabled={loading || questions.length === 0}
                className={`px-4 py-2 rounded-md ${
                  loading || questions.length === 0
                    ? 'bg-gray-600 cursor-not-allowed'
                    : 'bg-[#10A37F] hover:bg-[#0B8C6E]'
                }`}
              >
                Process Excel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AnswerQuestionsPage;